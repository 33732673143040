.app-simple-search-panel .app-simple-search-panel__input .v-input__slot {
  min-height: 36px;
  border-width: 1px !important;
}
.app-simple-search-panel .app-simple-search-panel__input .v-input__slot .v-text-field__slot input {
  margin: auto;
}
.app-simple-search-panel .app-simple-search-panel__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-simple-search-panel .app-simple-search-panel__input .v-text-field__details {
  display: none;
}
