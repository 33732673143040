
             
.app-simple-search-panel {
  .app-simple-search-panel__input {
    .v-input__slot {
      min-height: 36px;
      border-width: 1px !important;
      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
}
